import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import './Footer.css'
import { Link } from 'react-router-dom';


export default function App() {
  return (
    <MDBFooter  className='text-center text-lg-start text-white footer-colour'>
       <section className='d-flex justify-content-center justify-content-lg-between border-bottom'>
        
      </section>

      <section className='footer-con'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            {/* <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h4 className='text-uppercase fw-bold mb-4'>
                
              <img src='images/footerLogo.png' alt=''/>
              </h4>
              <p>
                Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet,
                consectetur adipisicing elit.
              </p>
            </MDBCol> */}
            

            <MDBCol md="2" lg="2" xl="2" className='footer-size h6'>
              <h6 className='text-uppercase fw-bold mb-4'>Quick Links</h6>
              <p>
                <Link to="/orderAndshipping" href='#!' className='text-reset'>
                Order and Shipping
                </Link>
              </p>
              <p>
                <Link to="/productcare" href='#!' className='text-reset'>
                Product Care
                </Link>
              </p>
              <p>
                <Link to="/faq" href='#!' className='text-reset'>
               FAQS
                </Link>
              </p>
              <p>
                <Link to="/returnandrefund" href='#!' className='text-reset'>
               Return
                </Link>
              </p>
              <p>
                <Link to="/terms" href='#!' className='text-reset'>
              Terms and Conditions
                </Link>
              </p>
              <p>
                <Link to='/about' className='text-reset'>
                About Us
                </Link>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                7-Day Returns
                </a>
              </p>
              <p>
                <a href='#!' className='text-reset'>
                Free Shipping
                </a>
              </p>
              <p>
                <Link to="/contact" href='#!' className='text-reset'>
                Contact Us
                </Link>
              </p>
            
            </MDBCol>

            {/* <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'> */}
              {/* <h6 className='text-uppercase fw-bold mb-4'>Advantage</h6> */}
              {/* <p>
                <a href='#!' className='text-reset'>
                7-Day Returns
                </a>
              </p>
             


              
              </MDBCol> */}


            <MDBCol >
            <h6 className='text-uppercase fw-bold mb-4'>NewsLetter</h6>

            <div class="input-group border border-dark  mb-3">
      <input type="text" class="form-control" placeholder="Your Email" name="email"/>
      <button class="input-group-text user-select-none">submit</button>
    </div>





              </MDBCol>
             

            <MDBCol md="4" lg="3" xl="3" className='ms-auto mb-md-0 mb-4 h6 footer-size'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                +9************00 (Helpline); 
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                co*******@caratlane.com
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                Corporate Enquiries 
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                careers@caratlane.com
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                For Grievance Enquiries 
              </p>

              <div className="icon-shift">
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
          </a>
        </div>

             
            
            </MDBCol>
            
            {/* <div className='row'>
              <div className='col-12'>
                <div className=''>
            <div class="hr3"></div>
             <div class="hr2"></div>
             </div>
             </div>
            </div>
           */}
           
           
          </MDBRow>
        </MDBContainer>
       
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
      COPYRIGHT © 2022 I9 INNOVATIONS & EDUCATIONS PVT, LTD.
        {/* <a className='text-reset fw-bold' href='https://mdbootstrap.com/'>
          
        </a> */}
      </div>
      
    </MDBFooter>
  );
}