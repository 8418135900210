import React from 'react'
import './Content.css'
import background from "../img/banner.png"
import img from "../img/icon1.png"
import check from "../img/icon2.png"
import container from "../img/icon3.png"
import free from "../img/icon4.png"

import {
  Link
 } from "react-router-dom";


const Content = () => {
  return (
  <div>
      <div className='container-fluid px-lg-5 px-md-3 px-sm-0 position-relative'>
     <section id="main-img" style={{ backgroundImage: `url(${background})`}}>
        <div className="container" >
          <div className='row '>
            <div className='col-lg-6 col-md-12  mt-5 '>
            <i className="italic" >Gifts for <span className="ctrl">Silvaroo</span></i>
            <hr className='horizontal'/>
             <p className='bond'>As special as your bond</p>
             <Link to="/shopnow">
             <button type="button" className="btn btn-outline-light btn-lg button " >SHOP NOW</button>
             </Link>
            </div>
            </div>
        </div>
        </section>

        
        

  <section className='curve-display container p-0'>
  <div className="icon-slider ">
    <div className='col-lg-9 col-md-12 col-12 curveSections'>
      <div className='row'>
    <div className='col-lg-3 col-sm-6'>
    <div className="vl"> 
    <div className='d-flex align-items-center'>
    <img src={img}/>
    <p>Fine Silver</p>
      </div>
  </div>
      </div>
      <div className="col-lg-3 col-sm-6">
      <div className="vl"> 
      <div className='d-flex align-items-center'>
        <img src={check}/>
        <p>6 Month Warranty</p>
        </div>
        </div>
        </div>
    <div className="col-lg-3 col-sm-6">
    <div className="vl"> 
    <div className='d-flex align-items-center'>
        <img src={container}/>
        <p>30 days return</p>
        </div>
        </div>
        </div>
    <div className="col-lg-3 col-sm-6">
      <div className='d-flex align-items-center'>
        <img src={free}/>
        <p>Free Delivery</p>
        </div>
        </div>
  </div>

        </div>
        </div>
  </section>
  </div>
  </div>
  
  )
}

export default Content