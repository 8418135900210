import React from 'react'
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart,faSearch,faHeart,faUser } from '@fortawesome/free-solid-svg-icons'


import img from '../img/Logo.png'

import {
 Link
} from "react-router-dom";





const Navbar = () => {
  return (
   
    <div>
   
  


      {/* <section id="main-img">
        <div className="container col-6">
        <i className="italic" >Gifts for <span className="ctrl"><h1>Silvaroo</h1></span></i>
        <h4>As special as your bond</h4>

        </div>
        </section> */}


<section id="Navbar">
  <div class="alert alert-secondary alert-center" role="alert">
    A simple danger alert—check it out!
  </div>
<nav class="navbar navbar-expand-lg navbar-light background">
 
  <div class="container-fluid">
  <a className="navbar-brand" href="#">
            <Link to="/" >
          <img  className="logo" src={img} alt="" width="200" heightcart-icon="50"/>
          </Link>
          </a>
          <div className="icons">
          <Link  to="/search" >
            <FontAwesomeIcon className='search-icon' icon={faSearch} />
            </Link>
            <Link to="/cart" >
            <FontAwesomeIcon className='cart-icon' icon={faShoppingCart} />

            </Link>

          <FontAwesomeIcon className='heart-icon' icon={faHeart} />
          <Link to="/user" >
          <FontAwesomeIcon className='user-icon' icon={faUser} />
          </Link>
          </div>
   
          <button className="navbar-toggler px-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarExampleOnHover" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
         <span className="navbar-toggler-icon"></span>
          </button>

   
    <div class="collapse navbar-collapse" id="navbarExampleOnHover">
     
      <ul class="navbar-nav mx-auto ps-lg-0 spacing " >
        <li class="nav-item dropdown dropdown-hover position-static">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
            data-mdb-toggle="dropdown" aria-expanded="false">
            Collections
          </a>
         
          <div class="dropdown-menu  " aria-labelledby="navbarDropdown"  >

            <div class="container">
            <div class="row  c-flex">
                <div class="col-lg-2 col-md-6">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action fw-bolder mega-menu">Shop by Catagories</a>
                    <a href="" class="list-group-item list-group-item-action fw-light">Rings</a>
                    <a href="" class="list-group-item list-group-item-action fw-light">Necklaces & Pendants</a>
                    <a href="" class="list-group-item list-group-item-action fw-light">Earrings</a>
                    <a href="" class="list-group-item list-group-item-action fw-light">Bracelets</a>
                    <a href="" class="list-group-item list-group-item-action fw-light">All</a>

                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action  fw-bolder">Shop by colour</a>
                    <a href="" class="list-group-item list-group-item-action">Gold</a>
                    <a href="" class="list-group-item list-group-item-action">Silver</a>
                    <a href="" class="list-group-item list-group-item-action">Rose Gold</a>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action  fw-bolder ">Shop By Stone</a>
                    <a href="" class="list-group-item list-group-item-action">ziron</a>
                    <a href="" class="list-group-item list-group-item-action">Coloured Stone</a>
                    <a href="" class="list-group-item list-group-item-action">Pearl</a>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action  fw-bolder">Shop By Style</a>
                    <a href="" class="list-group-item list-group-item-action">Office Minimals</a>
                    <a href="" class="list-group-item list-group-item-action">Party Pieces</a>
                    <a href="" class="list-group-item list-group-item-action">Mens</a>
                    <a href="" class="list-group-item list-group-item-action">Colour Blinks</a>
                  </div>
                </div>
                <div class="col-lg-2 col-md-6 ">
                  <div class="list-group list-group-flush">
                    <a href="" class="list-group-item list-group-item-action  fw-bolder">Treading</a>
                    <a href="" class="list-group-item list-group-item-action">Best Of Silvaroo</a>
                    <a href="" class="list-group-item list-group-item-action">Best Seller</a>
                    <a href="" class="list-group-item list-group-item-action">Lucky Charms</a>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </li>
           <li className="nav-item  dropdown dropdown-hover position-static">

                <a className="nav-link dropdown-toggle"  to="/cart">Gifts</a>
  <div class="dropdown-menu w-60 mt-0 px-0" aria-labelledby="navbarDropdown" >

<div class="container">
  <div class="row my-4">
    <div class="col-md-6 col-lg-3 mb-3 mb-lg-0 special-list">
      <div class="list-group list-group-flush ">
        <a href="" class="list-group-item list-group-item-action fw-bolder  ">By Price </a>
        <a href="" class="list-group-item list-group-item-action fw-light ">Below Rs.1,000</a>
        <a href="" class="list-group-item list-group-item-action fw-light ">Below Rs.2,000</a>
        <a href="" class="list-group-item list-group-item-action fw-light ">Below Rs.2,000-5,000</a>
        <a href="" class="list-group-item list-group-item-action fw-light ">Above 5,000</a>
        <a href="" class="list-group-item list-group-item-action fw-light ">All</a>

      </div>
    </div>
    <div class="col-md-6 col-lg-3 mb-3 mb-lg-0 special-list">
      <div class="list-group list-group-flush">
        <a href="" class="list-group-item list-group-item-action  fw-bolder">By Occasion</a>
        <a href="" class="list-group-item list-group-item-action">Gold</a>
        <a href="" class="list-group-item list-group-item-action">Silver</a>
        <a href="" class="list-group-item list-group-item-action">Rose Gold</a>
      </div>
    </div>
    <div class="col-md-6 col-lg-3 mb-lg-">
                  <div class="list-group list-group-flush special-list">
                    <a href="" class="list-group-item list-group-item-action  fw-bolder">By Recipient</a>
                    <a href="" class="list-group-item list-group-item-action">Sister</a>
                    <a href="" class="list-group-item list-group-item-action">Couples</a>
                    <a href="" class="list-group-item list-group-item-action">Special Onces</a>
                  </div>
                </div>

    </div>
    </div>
    </div>

              </li> 
               <li className="nav-item">
                <a className="nav-link" href="#">Best Of Silvaroo</a>
              </li> 
               <li className="nav-item">
                <a className="nav-link" href="#">Treading</a>
              </li> 
              <li className="nav-item dropdown dropdown-hover position-static">
                <a className="nav-link  dropdown-toggle" href="#">More</a>
  <div class="dropdown-menu w-60 mt-0 px-0" aria-labelledby="navbarDropdown" >

                <div class="container">

  <div class="row my-4">
    <div class="col-md-6 col-lg-3 mb-3 mb-lg-0 special-list1 ">
      <div class="list-group list-group-flush ">
        <Link to="/Trackorder"  class="list-group-item list-group-item-action fw-light  ">Track Order </Link>
        
        <Link to="/Jewellerycare" class="list-group-item list-group-item-action fw-light ">Jwellery Care</Link>
       
        <Link to="/Joinus">

        <Link to="/Joinus" class="list-group-item list-group-item-action fw-light ">Join Us</Link>
        </Link>
        <a href="" class="list-group-item list-group-item-action fw-light ">Support & faq</a>
        <a href="" class="list-group-item list-group-item-action fw-light ">Account</a>
        <a href="" class="list-group-item list-group-item-action fw-light ">Return & Exchange</a>

      </div>
    </div>
    </div>
    </div>
    </div>
              </li>
      </ul>
      <div className="icons1">
              <Link to="/search">
            <FontAwesomeIcon className='search-icon' icon={faSearch} />
            </Link>
            <Link to="/cart" >
            <FontAwesomeIcon className='cart-icon' icon={faShoppingCart} />

            </Link>

          <FontAwesomeIcon className='heart-icon' icon={faHeart} />
          <Link to="/user" >
          <FontAwesomeIcon className='user-icon' icon={faUser} />
          </Link>
           </div>
     
    </div>
   
  </div>
 
</nav>
</section>

        
    </div>
  )
  // var isActive = this.context.router.route.location.pathname === this.props.to;
  // var className = isActive ? 'active' :'';

}

export default Navbar