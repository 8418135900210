import React from 'react'
import Content from '../../Component/Content'
import Catagories from '../../Component/Catagories'
import SellingProduct from '../../Component/SellingProduct'
import Newproduct from '../../Component/Newproduct'
import Collections from '../../Component/Collections'
import Frame from '../../Component/Frame'
import Review from '../../Component/Review'
import './home.css'

const Home = () => {
  return (
    <>

<Content />
    <Catagories/>
    <SellingProduct/>
    <Newproduct/>
    <Collections/>
    <Frame/>
    <Review/>


    </>
  )
}

export default Home