import React from 'react'
import './user.css'
import {
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBCheckbox
}
from 'mdb-react-ui-kit';




const User = () => {
  return (
 <div>
          {/* <h2 className='text-center'>Login Page</h2><br/>    
    <div class="login" >    
    <form id="login" method="get" action="login.php"/>    
        <label><b className='login-page'>User Name</b>    
        </label>    
        <input type="text" name="Uname" id="Uname" placeholder="Username"/>    
        <br/><br/>    
        <label><b  className='login-page'>Password</b>    
        </label>    
        <input type="Password" name="Pass" id="Pass" placeholder="Password"/>    
        <br/><br/>    
        <input type="button" name="log" id="log" value="Log In Here"/>       
        <br/><br/>    
        <input type="checkbox" id="check"/>    
        <span className='check'>Remember me</span>    
        <br/><br/>    
        Forgot <a href="#">Password</a>    
        <form/>   

  
</div>   */}

<MDBContainer fluid className="p-3 my-5 container">

<MDBRow>

  <MDBCol col='10' md='6'>
    <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg" class="img-fluid" alt="Phone image" />
  </MDBCol>

  <MDBCol col='4' md='6'>


    <MDBInput wrapperClass='mb-4'  id='formControlLg' type='email' size="lg" placeholder='Email address'/>
    <MDBInput wrapperClass='mb-4' id='formControlLg' type='password' size="lg"  placeholder='Password'/>


    <div className="d-flex justify-content-between mx-4 mb-4">
      <MDBCheckbox name='flexCheck' value='' id='flexCheckDefault' label='Remember me' />
      <a href="!#">Forgot password?</a>
    </div>

    {/* <MDBBtn className="btn btn-primary btn-lg" >Sign in</MDBBtn> */}
    <div class="d-grid gap-2">
  <button class="btn btn-primary" type="button">Button</button>
</div>    

    

  </MDBCol>

</MDBRow>

</MDBContainer>



</div>


     )
}

export default User