import React from 'react'
import './Faq.css'


import {
    Link
   } from "react-router-dom";

const Faq = () => {
    

//use <a> it will not affect the code ok

  return (
<div class="container"><br/><br/>
        <center><h2 style={{textAlign: "center",color: "#00b7eb"}}>Frequently <span style={{color: "#FFD700"}}>Asked </span>Questions</h2></center><br/><br/>

        <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="row">  
      <div class="col-sm-5">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      1. How can I track my order?
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">When your order is shipped you will get a shipment tracking number. By using this you can easily track your order. So you need not worry about your order. Besides it, We will keep sending you details of your order on your phone number and {<Link to="/https://stackoverflow.com/questions/7151401/sql-query-for-finding-records-where-count-1"> email </Link> } . You can also track the status of your order here:
</div> 

    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      2. What happens if I receive an incorrect item or an item is missing from my order?
      </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">In case you have received an incorrect item or you just want to exchange your jewellery for another piece, SILVAROO allows you to replace the jewellery with the same product or exchange it with another product for up to 15 days from the time of delivery of the order.
If you received an incomplete item then you need to contact us via sending an email at  customercare@silvaroo.in or call us at <strong style={{color: "green"}}>+91-9987886160</strong> 
</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      3. If I want to cancel my order what should I do? 
      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Sorry to hear that you want to cancel your order. You need to get login at our official website and cancel your order there.
For our guidance, you can give us the reason why you want to cancel it.
</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
      4.How should I care for my SILVAROO jewellery?
      </button>
    </h2>
    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      The most important thing is the shining of jewellery. If the shine of your jewellery is lost, it is useless to wear jewellery. 
       Here are some precautions that you should obey when you are wearing SILVAROO jewellery
   <ol>
    <li> 1. Keep away from water, Direct sunlight, and Perfumes, </li>
	<li>2. Keep your jewellery at moderate temperature.</li>

	
    </ol>
    
</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingFive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
      5.How should I clean my SILVAROO jewellery?
      </button>
    </h2>
    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">
      Well, SILVAROO Jewelry is coated with a protective layer due to this the chances of your jewellery getting grubby become reduced. 
      But somehow if it gets grubby you can easily clean your jewellery. 
      You just need to clean it with a normal piece of cloth without applying any chemical detergents.
    
</div>
    </div>
  </div>
</div>
{/* <!----col end-----> */}

<div class="col-sm-2"></div>
<div class="col-sm-5">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
      6.. How can I contact SILVAROO Jewelry care center?
      </button>
    </h2>
    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">If you want to contact us, You can easily contact us while sending us an email at
        customercare@silvaroo.in, or instead of this, 
      you can also contact us at our official
       contact number&nbsp;  <strong style={{color:"green"}}>+91-9987886160</strong>
    
</div>
    </div>
  </div>


  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingSeven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
      7. Will my jewellery look the same as on the website?
      </button>
    </h2>
    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">We are trying our best to upload the product picture as same as the product.
       However, some designs may be different due to lightning problems. In case of this,
       you can contact us at customercare@silvaroo.in.
    
</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingEight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
      8. What are your product delivery times?
      </button>
    </h2>
    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Orders are usually processed within 1-3 working days. Sometimes due to abnormal conditions,
       we may not be able to deliver it. In such type of condition, 
      we contact our Lovely costumes as soon as possible.
    
</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingNine">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
      9. How do I return my wrong order to Silvaroo?
      </button>
    </h2>
    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Once a return request has been raised on the web or by mail,
       our pickup partners will reverse pick up your order in 3 working days from the day of booking the return.
       As soon as possible you are notified about a successful return pick-up.
    
</div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTen">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
      10. I need to change the Shipping address. What should I do?
      </button>
    </h2>
    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">If your order was not picked from our warehouse then we surely change your address. 
       Whereas in other cases when your order was shipped at that condition our delivery requires some time to call our product back and 
      send it again to your new delivery address.
    
</div>
    </div>
  </div>
  </div> 
</div>
</div>

<br/>

    </div>
  )
}

export default Faq