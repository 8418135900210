import { render } from '@testing-library/react'
import React, {useState}from 'react'
import './Maincard.css'



const Maincard = () => {
  const [currentImage, setCurrentImage] = useState('./images/image.png')


  return (
    <div>




      <div class="container mt-5 mb-5" >
        <div class="card">
          <div class="row g-0">
            <div class="col-md-6 border-end">
              <div class="d-flex flex-column justify-content-center">
                <div class="main_image">
                  <img src={currentImage} id="main_product_image" width="350" />
                </div>
                <div class="thumbnail_images">
                  <ul id="thumbnail">
                    <li>
                      <img onClick={() => {
                        console.log("In Loop 1");
                        // changeImage()
                        setCurrentImage("./images/Rectangle 17 (2).png")
                      }} src="./images/Rectangle 17 (2).png" width="70" />
                    </li>	<li>
                      <img onClick={() => {
                        console.log("In Loop 2");
                        // changeImage()
                        setCurrentImage("./images/Rectangle 17 (3).png")
                      }} src="./images/Rectangle 17 (3).png" width="70" />
                    </li>
                    <li><img onClick={() => {
                      console.log("In Loop 3");
                      // changeImage()
                      setCurrentImage('./images/Rectangle 17 (1).png')
                    }} src="./images/Rectangle 17 (1).png" width="70" />
                    </li>
                  </ul>

                </div>
              </div>
            </div>
            <div class="col-md-6">	<div class="p-3 right-side">
              <div class="d-flex justify-content-between align-items-center">
                <h3 style={{ fontSize: "1.5rem" }}>Anushka Sharma Silver Zircon Pendant with Link Chain</h3>
                {/* <span class="heart">
                                                                <i class='bx bx-heart'></i>
                                                            </span>	 */}
              </div>
              <div class="mt-2 pr-3 content">
                <p>MADE WITH PURE 925 SILVER</p>
              </div>
              <h3>$430.99</h3>
              <div class="rating">4.8
                <span class="star">★</span> | 240 REVIEWS
              </div>
              <div class="ratings d-flex flex-row align-items-center">
                <div class="d-flex flex-row">
                  <i class='bx bxs-star' ></i>
                  <i class='bx bxs-star' ></i>
                  <i class='bx bxs-star' ></i>
                  <i class='bx bxs-star' ></i>
                  <i class='bx bx-star' ></i>
                </div>

              </div>

              {/* <div class="mt-5">	
                                                                                    <span class="fw-bold">Color</span>
                                                                                    	<div class="colors">
                                                                                            	<ul id="marker">	
                                                                                                    <li id="marker-1"></li>
                                                                                                    	<li id="marker-2"></li>
                                                                                                        	<li id="marker-3"></li>	
                                                                                                            <li id="marker-4"></li>
                                                                                                            	<li id="marker-5"></li>	
                                                                                                            </ul>
                                                                                                        	</div>
                                                                                                        	</div>  */}

              <label for="gift_wrap" class="c-lbl"><input class="c-chek-box" type="checkbox" id="gift_wrap" name="gift_wrap" value="gift_wrap" />
                Add Gift Wrap to your Order (Rs. 50)
              </label>
              <div class="d-grid gap-3">
                <button type="button" class="btn  btn-block background-add-to-cart">ADD TO CART</button>
                <button type="button" class="btn btn-block background-buy-it-now" style={{ color: "#FEBD69" }}>BUY IT NOW</button>
                <button type="button" class="btn btn-block btn-outline-dark">Add TO WISHLIST</button>
              </div>
              {/* <div class="buttons d-flex flex-row mt-5 gap-3">
                                                                                                                	<button class="btn btn-outline-dark">Buy Now</button>
                                                                                                                    	<button class="btn btn-dark">Add to Basket</button>
                                                                                                                    	</div>	  */}
              {/* <div class="search-option">
                                                                                                                            	<i class='bx bx-search-alt-2 first-search'></i>	
                                                                                                                                <div class="inputs">	
                                                                                                                                <input type="text" name=""/>
                                                                                                                                	</div>	
                                                                                                                                    <i class='bx bx-share-alt share'></i>
                                                                                                                                	</div>	 <br/><br/> */}
              <center>
                <ul class="tags product__policies ">

                  <li id="returns"><div class="product__policies_div"><img class="img-responsive lazyloaded" data-src="" src="images/8.png" /></div></li>



                  <li id="certificate"><div class="product__policies_div"><img class="img-responsive lazyloaded" data-src="" src="images/certificate_small.webp" /></div></li>



                  <li id="women"><div class="product__policies_div"><img class="img-responsive lazyloaded" data-src="" src="images/Female.webp" /></div></li>



                  <li id="warranty"><div class="product__policies_div"><img class="img-responsive lazyloaded" data-src="" src="images/7.png" /></div></li>

                </ul>
              </center>
              <div class="accordion accordion-flush" id="accordionFlushExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingOne">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                      SPECIAL OFFERS
                    </button>
                  </h2>
                  <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the first item's accordion body.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                      PRODUCT DESCRIPTION
                    </button>
                  </h2>
                  <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the second item's accordion body. Let's imagine this being filled with some actual content.</div>
                  </div>
                </div>
                <div class="accordion-item">
                  <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                      SHIPPING DETAILS
                    </button>
                  </h2>
                  <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>




    </div>







  );


}

export default Maincard