import React from 'react'
import './Jewellerycare.css'
const Jewellerycare = () => {
  return (
    <div>
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2 style={{textAlign:"center"}}>JEWELLERY CARE</h2>
                <p class="subtitle">Hey Gorgeous!</p>
                <p style={{textAlign:"center", fontSize: "3vh"}} class="">At GIVA, we believe life is about Expressing yourself. Your jewellery is nothing but a medium to channel you feelings.<br/>
                 So here are some tips to take care of your precious GIVA treasures.
               </p>
              
            </div>
        </div>
        </div><br/>

<div class="container-fluid" style={{backGround:"#fcdde3"}}>
 <div class="row">
            <div class="col-sm-12">
            <div class="boxes">
  	        <div class="box">
    	<h3>QUICK TIPS</h3>
      <p>Your GIVA Jewellery is as precious and special as YOU!</p>
      <p>It should be the last thing you wear and the first thing you take off.</p>
    </div>
    <div class="box">
    	<h3>DO REMEMBER</h3>
      <p>Every piece of GIVA Jewellery is coated with Rhodium to ensure  a lasting shine.</p>
      <p>These are some additional tips to make sure it stays by your side .</p>
    </div>
  </div>
            </div>
        </div> 
        </div><br/><br/>

        <div class="container">
         <div class="row">
            <div class=" col-sm-3">
           <center> <img class="img-fluid" src="images/zip_lock.webp" width="200px"/></center>
            </div>

            <div class="col-sm-9 ">
            
    	<p style={{fontSize: "19px",fontFaimily:"Avenir Next,sans-serif"}}>Your GIVA Jewellery is unique, brilliant and charming,
             just like YOU! To make sure it remains that way,</p><p style={{fontSize: "19px"}}> 
             you need to make sure it has a safe home.</p><br/>
    	
      <p style={{fontSize: "19px"}}>All you have to do is  simply store these beautiful pieces in
         your GIVA Kit or a Zip Lock Bag. Keep them </p><p style={{fontSize: "19px"}}>safe and they'll
          always be by your side.</p>
    
            </div>

            
        </div> <br/>
        <div class="end">
            <span class="dot1"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            </div>
        <br/><br/><br/><br/>

        <div class="row">
            <div class=" col-sm-8">
            <p style={{fontSize: "19px"}}>Your GIVA Jewellery is as gentle as YOU are and needs the same love and care.
            </p><br/>
    	
      <p style={{fontSize: "19px"}}>Perfumes, soap, strong chemicals and excessive humidity are harsh not just for your jewellery, but also for your skin!
       Best to avoid or at least limit direct contact with them.</p>
           
            </div>

            <div class="col-sm-4">
            <center> <img class="img-fluid" src="images/soap.webp" width="400px" height="300px"/></center>
    	
    
            </div>

            
        </div><br/> <br/><br/>

        <div class="end">
            <span class="dot1"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            <span class="dot"></span>
            </div><br/><br/><br/>

            <div class="row">
            <div class=" col-sm-4">
            <center> <img class="img-fluid" src="images/soap.webp" width="400px" height="300px"/></center>
            
           
            </div>

            <div class="col-sm-8">
          
            <p style={{fontSize: "19px"}}>Your GIVA Jewellery is as gentle as YOU are and needs the same love and care.
            </p><br/>
    	
         <p style={{fontSize: "19px"}}>Perfumes, soap, strong chemicals and excessive humidity are harsh not just for your jewellery, but also for your skin!
            Best to avoid or at least limit direct contact with them.</p>
    
            </div>

           
        </div><br/> <br/>
        <center><button type="button" class="btn btn-secondary">Take me to Bestsellers</button></center>
        </div><br/> <br/>
   
    </div>
  )
}

export default Jewellerycare