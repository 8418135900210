import React from 'react'
import './Search.css'
import { Link } from 'react-router-dom';


function Search() {
  return (
    <div  class="container">

         <form class="d-flex search-bar" role="search">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>


      <div class="container text-center search-card">
  <div class="row">
    <div class="col">
    <div class="card card1">
  <img src="images/Rectangle 17 (1).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
  <Link to="/maincard">
    <a  class="btn btn-outline-dark cardbtn">Go somewhere</a>
</Link>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (2).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href='/' class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (3).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17.png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
  </div>
    </div>  


    <div class="container text-center search-card">
  <div class="row">
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (1).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (2).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (3).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17.png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
  </div>
    </div>  


    </div>
  )
}

export default Search