import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import './Terms.css'

const Terms = () => {
  return (
    <div class="container">
     <div class="row">   
     <div class="col-sm-12"><h1 class="term"><span style={{color: "#00b7eb"}}>Terms an</span><span style={{color: "#FFD700"}}>d Conditions </span></h1></div>
     </div>
     <br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">OVERVIEW</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">Welcome to the Official Website of SILVAROO jewelry. All the Information mentioned here is from Silvaroo Jewelry.
         These terms and conditions are for all the people who visit our website. 
        By visiting our official website you get to know our extraordinary jewelry product.</p><br/>

        <p>By visiting our website or placing an order you have to accept all our terms and conditions. 
            Everyone must accept all our terms. If you do not accept these Terms and conditions you are not able to place your order.
             Before accepting it you must need to read it completely so that it will keep you safe from big trouble.</p><br/>

             <p>
                You need to check the website's latest version so that you will be able to check all
                 the advanced services. We have a right to change the “terms and services” at any time.
                  But you have to check the page at regular intervals so that it will help you in placing an order efficiently.
            </p>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 1 GENERAL CONDITIONS:</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We have a right to cancel the provided services to any of the people at any time. 
        We assure you that your all credit card information is safe but your content is transferred for the linking of devices. 
        You are not allowed to copy and enroll the conditions without taking permission from us. 
        We provide you with a full guarantee of the safety of your information. </p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 2 MODIFICATION IN PRICES AND SERVICES:</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We have a right to enlist or to change the prices of our jewelry products or to change our services with the time.
         We are not eligible to notify you about our changes. This right is not for every people or third party.
          We have a right to update our product details as well as price, sometimes we update a sale on specific products at a specific time.
         So you have to keep your eye on our official website.</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 3 ONLINE STORE AND SHOPPING </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We provide our service online and you are not allowed to misuse our products as it proceeds you toward trouble we’ll remove you from all the services then you are not allowed to visit our website. 
         Your Online Orders are processed within 1-3 working days.
We're continuously trying to get you your order as soon as possible, however, in some unwanted conditions, it may take a bit longer. 
In this case, we'll notify you of this delay with its causes. There is also a free shipping offer only for orders above Rs. 100.
 We are also providing you the facility to track your order because everyone wants to know everything about your product.
</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 4  ACCURACY, COMPLETENESS, AND TIMELINESS OF INFORMATION</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We have a well right to update all the information regarding the product. 
        If there is a problem while using websites such as the disappearance of product details then we are not responsible for this. 
        We are not responsible for all the incomplete product details. While accepting all terms and conditions, you also accept that we are not responsible to notify the modification on the website but you have to keep eye on it.
         A most important thing is that we do not have any right to change or update the information that you provided on the website.
         Any stand-by on the website is your responsibility. We only provide common information about the product so before placing your order you need to confirm the information from the right source. 
</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 5  THIRD PARTY LINK</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">Our website may include third parties websites that we are not allowed to control. We are not responsible for the accuracy of these websites.
         One main thing that needs your attention is that we are not responsible for any harm to the product which you buy and are associated with the third party. 
         While placing your order, make sure that you’ll get all the important information required from the third party. While giving your review to a third party you must check the third party policy
         Complaints and questions should be asked directly from the third party. We cannot answer those questions related to the third party.

</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 6  OPTIONAL TOOLS</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We sometimes may provide an optional tool to you and permit you to access the third-party tools but we have no access to the third party. 
        You can use any optional tool at your own risk. We are not responsible for the optional tools associated with the third party.
         We also update our optional tool after time so you need to check it because after updating we cannot send any notification to you.

</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 7  PRODUCT OR SERVICE  (if required)</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We work on customer satisfaction. In this regard, 
        we are trying to attach the exact picture of the product but if there is sometimes the product can't be the same as in the picture so be careful before placing an order.
         
         If there is some color issue in your window screen then we cannot solve that type of issue. If you are not feeling comfortable with your order then you can cancel the order at any time. We have a right to cease the product and its relevant services. If the delivered product is not as same as your order then don’t worry about that because We also have a 7-day no-questions-asked return policy.
         We also introduce the exchange of unused products just for our happy customers.

</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 08  ACCURACY OF BILLING AND ACCOUNT INFORMATION </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We have a right to cancel your order. But when you pay for many orders on the same credit card or same billing address then in such types of situations, we always try to contact our customers by email or other ways and find out the solution to this issue.
         We may set some of our limits for the confirmation of the order that you place with us.
         While accepting our policy you have to give us the right information about yourself such as the billing address or credit information and you have to update your Personal information at regular intervals. This will help us to contact you if needed. You have to pay our payment of your order by the VISA or MASTERCARD credit/ debit card.
          For our respectful customers,
          we are not including the taxes in the product price.


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 08  ACCURACY OF BILLING AND ACCOUNT INFORMATION </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We have a right to cancel your order. But when you pay for many orders on the same credit card or same billing address then in such types of situations, we always try to contact our customers by email or other ways and find out the solution to this issue.
         We may set some of our limits for the confirmation of the order that you place with us.
         While accepting our policy you have to give us the right information about yourself such as the billing address or credit information and you have to update your Personal information at regular intervals. This will help us to contact you if needed. You have to pay our payment of your order by the VISA or MASTERCARD credit/ debit card.
          For our respectful customers,
          we are not including the taxes in the product price.


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 9  USER COMMENTS, FEEDBACK, AND OTHER SUBMISSIONS </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">Your feedback is also very important as you.
         You have a right to send us some suggestions for improvement in jewelry design and quality that you have to send us via email or comment us on social media, and we have also a right to publish your ideas and suggestions on social media.
          We have a right to edit the published information on the website. You should agree with the fact that you will not post substances that contain copyrights or some other issues.
         We don't take any responsibility for your comments and 
         third-party comments. You don't have the right to give your wrong email address while posting comments, it not only affects the third party but also affects your right as we mentioned above that we have the right to cancel your services. 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 10  PERSONAL INFORMATION </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">You must have to give us your right Personal Information including your name, address,
         and contact number. your personal information is always checked by us. By providing us with the wrong information you are unable to contact us. 
        SILVAROO website provides you with all the safety of your personal information. 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 11   PROHIBITED USES </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">You are not allowed to make illegal use of the content on the website and not to harass someone on our social media pages and any other representation or not to spread false and misleading news about us. If you will do any such type of work then we have to take some unwanted steps that not only disturb your order but also cause a severe problem for you.
         You don’t have any option to change our security system on the website.
         If you constantly try to change our website's security system then keep notice that your account may be banned. 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 12  ERRORS, INACCURACIES, AND OMISSIONS</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">Rarely there are some issues in issue in the website due to this the product details on the website may be incorrect.
         But you need not worry about it. We are here to solve all the errors regarding our website.
          We’ll cancel all the products if the information provided by ourselves or by yourselves is not correct. 
        We do not give any specific date regarding the change in the services. So be careful about it.


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 13  INDEMNIFICATION</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We are glad to introduce our policy for compensation for harm and loss which can occur in our SILVAROO jewelry product.
         In such types of situations, our valuable customers have an option to send our product back.
          Pincode are given by our courier partner if the Pincode is missed from yourself then you have a responsibility to send it to our warehouse address.


</p><br/>
    </div>
     </div><br/>


     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 14 - TERMINATION</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">If you don't want to use our services and stop using our website, you have to notify us before leaving our website. 
        Sometimes if we feel inappropriate things, we also have a right to remove you and stop all the services provided to us without proper notification.
         Any illegal activity attempt by you can lead to the direct elimination from the website.


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 15  DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">As you already agree to our services, we can change our service at any time without notifying you. 
        You can use our services or ceases them at any time. Our business services are provided to you when you agree with our agreement.
         We make a no warranty for the wrong information presented on the website by a third party.
         You must agree with the fact that any harm or loss is not the responsibility of our Employees.


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 16  SEVERABILITY</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">Any disobey of the agreement from you can make you unable to use our helpful services. We serve you all the legal services. 
        In cases of having no services you should contact 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 17  GOVERNING LAW</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">All the services and special offers provided by us are verified by the Government and all are according to our law. 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 18  CHANGING OF TERMS AND SERVICES</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">We have a right to change our terms and services according to ourselves. In changing services and terms you will not get any notification so to keep using your website you need to update your device regularly to get the most advanced or modified website.
         After updating it you will be able to re-use our website and make proper use of our services. 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 19  ENTIRE AGREEMENT</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">These “TERMS AND SERVICES” are the complete agreement that is signed between our valuable customers and us. Make sure you read the full terms and conditions to avoid trouble.
No changes are done in the Terms and Services. Hope you’ll corporate with us.
 


</p><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading">SECTION 20  CONTACT US</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p class="">If you have any questions related to our jewelry products then you can contact us at any time.
        Send us an email at  www.care@silvaroo.com

 


</p><br/>
    </div>
     </div><br/>

     <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset' >
            <MDBIcon fab icon="facebook-f"style={{color:"#4267B2"}} />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" style={{color:"#1DA1F2"}} />
          </a>
          
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" style={{color:"#fb3958"}} />
          </a>
          
          
        </div>
      </section>
    </div>
  )
}

export default Terms