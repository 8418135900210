import React from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

import "./Contact.css"



const Contact = () => {
  return (
    <div class="container">
      {/* <!--Section: Contact v.2--> */}
<section class="mb-4">

    {/* <!--Section heading--> */}
    <h2 class="h1-responsive font-weight-bold text-center my-4"><span style={{color: "#00b7eb"}}>Conta</span><span style={{color: "#FFD700"}}>ct us</span></h2>
    {/* <!--Section description--> */}
    <p class="text-center w-responsive mx-auto mb-5">Do you want to keep in touch with us? So you need not worry we are always here to solve all your problems. 
    So why are you hanging back? Let's make a chat with us.</p>

    <div class="row">
     <div class="col-md-2"></div>
        {/* <!--Grid column--> */}
        <div class="col-md-8 mb-md-0 mb-5">
            <form id="contact-form" name="contact-form" action="mail.php" method="POST">

                {/* <!--Grid row--> */}
                <div class="row">

                    {/* <!--Grid column--> */}
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input type="text" id="name" name="name" class="form-control" placeholder='Your name'/>
                            <label for="name" class=""style={{visibility: "hidden"}}>Your name</label>
                        </div>
                    </div>
                    {/* <!--Grid column--> */}

                    {/* <!--Grid column--> */}
                    <div class="col-md-6">
                        <div class="md-form mb-0">
                            <input type="text" id="email" name="email" class="form-control" placeholder='Your email'/>
                            <label for="email" class=""style={{visibility: "hidden"}}>Your email</label>
                        </div>
                    </div>
                    {/* <!--Grid column--> */}

                </div>
                {/* <!--Grid row--> */}

                {/* <!--Grid row--> */}
                <div class="row">
                    <div class="col-md-12">
                        <div class="md-form mb-0">
                            <input type="text" id="subject" name="subject" class="form-control" placeholder='Subject'/>
                            <label for="subject" class=""style={{visibility: "hidden"}}>Subject</label>
                        </div>
                    </div>
                </div>
                {/* <!--Grid row--> */}

                {/* <!--Grid row--> */}
                <div class="row">

                    {/* <!--Grid column--> */}
                    <div class="col-md-12">

                        <div class="md-form">
                            <textarea type="text" id="message" name="message" rows="2" class="form-control md-textarea" placeholder='Your message'></textarea>
                            <label for="message" style={{visibility: "hidden"}}>Your message</label>
                        </div>

                    </div>
                </div>
                {/* <!--Grid row--> */}

            </form>

            <div class="text-center text-md-left d-grid">
                <a class="btn  btn-block btn-primary" onclick="document.getElementById('contact-form').submit();">Send</a>
            </div>
            <div class="status"></div>
        </div>
        {/* <!--Grid column--> */}

        {/* <!--Grid column--> */}
        {/* <div class="col-md-3 text-center">
            <ul class="list-unstyled mb-0">
                <li><i class="fas fa-map-marker-alt fa-2x"></i>
                    <p>San Francisco, CA 94126, USA</p>
                </li>

                <li><i class="fas fa-phone mt-4 fa-2x"></i>
                    <p>+ 01 234 567 89</p>
                </li>

                <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                    <p>contact@mdbootstrap.com</p>
                </li>
            </ul>
        </div> */}
        {/* <!--Grid column--> */}

    </div><br/><br/><br/><br/>

    <div class="row">
    <div class="col-md-4 text-center">
        <i class="fas fa-phone mt-4 fa-2x"style={{color:"#1DA1F2"}}></i>
        <p>We provide you with 24/7 service. You can contact us anytime on our official Number</p>
                    <p>+91-9987886160</p>
            
                </div> 

      <div class="col-md-4 text-center" >
            
                <i class="fas fa-map-marker-alt fa-2x"style={{marginTop:"24px",color:"#1DA1F2"}}></i>
                    <p>Address-201/Shivam apartment Ashok Nagar Kandivali (east) Mumbai</p>
                    <p>400101</p>
                

                
           
           </div>

           <div class="col-md-4 text-center">
        
                <i class="fas fa-envelope mt-4 fa-2x"style={{color:"#1DA1F2"}}></i>
                <p>If you want to know something about us you can also send an email at</p>
                    <p>costumercare@silvaroo.in</p>
                
            
        </div>

        </div>

    

</section>

<section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset' >
            <MDBIcon fab icon="facebook-f"style={{color:"#4267B2"}} />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" style={{color:"#1DA1F2"}} />
          </a>
          
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" style={{color:"#fb3958"}} />
          </a>
          
          
        </div>
      </section>
{/* <!--Section: Contact v.2-->   */}

    </div>
  )
}

export default Contact