import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
// import "swiper/css/pagination";
import "swiper/css/navigation";
import {Autoplay, Navigation } from "swiper";
import { Link } from 'react-router-dom';


import "./Catagories.css";

// import required modules
// import { Pagination } from "swiper";
// import axios from "axios";

export default function App() {



  return (
    <>
    <section id="dim" >
    <div className="container">
  <div className="row swiperBtn btn-space">
    <div className="col-lg-5 col-md-12 col-12 ">
      <div className="slide">
    <i className="crousel" >See Us <span className="robot">Catagories</span></i>
             <h4 className='letter'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h4>
             </div>
    </div>
    <div className="col-lg-7 col-md-12 col-12 catagories-slide ">
    <Swiper

      breakpoints={{
        375: {
          width: 200,
          slidesPerView: 1,
        },
        // 768: {
        //   width: 300,
        //   slidesPerView: 1,
        // },
      }}



        slidesPerView={2}
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay:1500000000,
          disableOnInteraction: false,
      }}
     
        navigation={true}
         modules={[Autoplay,Navigation]}
     
        className="mySwiper"
      >
        
         <SwiperSlide>

         <div className="card-image">
          <Link to="/maincard">

          <img src="images/ring.png" alt="clients review"/>
                <p className='card-img-text'>Ring</p>
         </Link>

           </div>
                
                 
         </SwiperSlide>

        <SwiperSlide>
        <div className="card-image">
          <img src="images/ring.png" alt="clients review"/>
                <p className='card-img-text'>Necklaces & Pendants</p>
           </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="card-image">
          <img src="images/ring.png" alt="clients review"/>
                <p className='card-img-text'>Pendents</p>
           </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="card-image">
          <img src="images/ring.png" alt="clients review"/>
                <p className='card-img-text'>ring</p>
           </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className="card-image">
          <img src="images/ring.png" alt="clients review"/>
                <p className='card-img-text'>ring</p>
           </div></SwiperSlide>
        <SwiperSlide>
        <div className="card-image">
          <img src="images/ring.png" alt="clients review"/>
                <p className='card-img-text'>ring</p>
           </div>
        </SwiperSlide>
       
      </Swiper>
 
    </div>
  </div>
  </div>
  </section>
    </>
    
  );
}
