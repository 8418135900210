import './App.css';
import Navbar from './Component/Navbar';
import Footer from './Component/Footer';


import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Cart from './Pages/cart/Cart';
import Home from './Pages/home/Home';
import User from './Pages/user/User';
import Search from './Pages/search/Search';
import Womens from './Pages/womens/Womens';
import Mens from './Pages/mens/Mens';
import Content from './Component/Content';
import Shopnow from './Pages/shopnow/Shopnow';
import Faq from './Pages/faq/Faq';
import Contact from './Pages/contact/Contact';
import Maincard from './Pages/maincard/Maincard';
import Terms from './Pages/terms/Terms';
import Orderandshipping from './Pages/orderAndshipping/Orderandshipping';
import About from './Pages/about/About';
import Returnandrefund from './Pages/returnandrefund/Returnandrefund';
import Productcare from './Pages/productcare/Productcare'; 
import Trackorder from './Pages/trackorder/Trackorder';
import Jewellerycare from './Pages/jewellerycare/Jewellerycare';
import Join from './Pages/joinus/Join';











function App() {
 

  return (
    <div>
    



    <BrowserRouter>

{/* this is header */}
    <Navbar/>

      <Routes>
          <Route  path="/"   element={   <Home/>  }  />
          <Route  path="/cart"   element={  <Cart/>  }  />
          <Route  path="/user"  element={  <User /> } />
          <Route  path="/search"   element={  <Search/>    }  />
          <Route  path="/womens"  element={ <Womens/>}/>
          <Route  path="/mens"    element={ <Mens/>}/>
          <Route  path='/shopnow'  element={ <Shopnow/>}/>
          <Route  path='/faq'     element={<Faq/>}/>
          <Route  path='/maincard'     element={  <Maincard/>   }/>
          <Route  path='/contact'     element={<Contact/>}/>
          <Route  path='/terms'     element={<Terms/>}/>
          <Route  path='/orderandshipping'  element={<Orderandshipping/>}/>
          <Route  path='/about'     element={<About/>}/>
          <Route  path='/returnandrefund'  element={<Returnandrefund/>}/>
          <Route  path='/productcare'   element={<Productcare/>}/>
          <Route path='/trackorder' element={< Trackorder/>}/>
         <Route path='/jewellerycare' element={<Jewellerycare />}/>
         <Route path='/joinus' element={<Join/>}/>




      </Routes>


{/* this is footer */}
      <Footer/>



    </BrowserRouter>
    

    </div>
  
  );
}

export default App;
