import React from 'react'
import './Join.css'

const Join = () => {
  return (
    <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 career-banner">
         <center>
          <h1 class="build-heading">BUILD AWESOME CAREERS</h1>
          <p class="sub-head">Join the world of SILVARO</p>
         </center>
        </div>
      </div><br></br>

      <div class="row">
        <div class="col-sm-12">
         
          <h2 class="why-Head">Why SILVARO</h2>
          
        
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-4 why-Block">
         <h5>Mission</h5>
         <img class="" src='/images/mission.png'></img>
         <p class="why-con">Our mission is to bring a smile to your face. We do this with modern
          silver jewellery that is as beautiful and elegant as you.</p>
        </div>
        
        <div class="col-sm-4 why-Block">
        <h5>Vision</h5>
        <img class="" src='/images/mission.png'></img>
         <p class="why-con">Our mission is to bring a smile to your face. We do this with modern
          silver jewellery that is as beautiful and elegant as you.</p>
        </div>
        
        <div class="col-sm-4 why-Block">
        <h5>Our Promise</h5>
        <img class="" src='/images/mission.png'></img>
         <p class="why-con">Our mission is to bring a smile to your face. We do this with modern
          silver jewellery that is as beautiful and elegant as you.</p>
        </div>
      </div>
    </div><br></br>

    
      

{/* <!----dfdf--------> */}
<div class="container">
<div class="row">
        <div class="col-sm-12">
         
          <p class="why-Head">Life @ SILVAROO</p>
          
        
        </div>
      </div>
<div class="row"style={{marginTop:"-36px"}}>
<div class="col-sm-2"></div>
<div class="col-sm-8">
<div id="demo" class="carousel slide" data-bs-ride="carousel"style={{border:"6px solid #ffdde4",borderRadius:"8px"}}>

  
<div class="carousel-indicators">
    <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active" style={{backgroundColor:"red"}} ></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="1"style={{backgroundColor:"red"}}></button>
    <button type="button" data-bs-target="#demo" data-bs-slide-to="2"style={{backgroundColor:"red"}}></button>
  </div>
  
  
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="https://www.dpsbudaun.com/images/Slider/sld2022july_4.jpg" alt="Los Angeles" class="d-block" style={{width:"100%;",height:"600px"}}/>
      <div class="carousel-caption">
        {/* <h3>Los Angeles</h3>
        <p>We had such a great time in LA!</p> */}
      </div>
    </div>
    <div class="carousel-item">
      <img src="https://www.dpsbudaun.com/images/Slider/sld2022july_3.jpg" alt="Chicago" class="d-block" style={{width:"100%;",height:"600px"}}/>
      <div class="carousel-caption">
        {/* <h3>Chicago</h3>
        <p>Thank you, Chicago!</p> */}
      </div> 
    </div>
    <div class="carousel-item">
      <img src="https://www.dpsbudaun.com/images/Slider/sld2022july_6.jpg" alt="New York" class="d-block" style={{width:"100%",height:"600px"}}/>
      <div class="carousel-caption">
        {/* <h3>New York</h3>
        <p>We love the Big Apple!</p> */}
      </div>  
    </div>
  </div>
  
  
  <button class="carousel-control-prev" type="button" data-bs-target="#demo" data-bs-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#demo" data-bs-slide="next">
    <span class="carousel-control-next-icon"></span>
  </button>
  
  
</div>

<div class="col-sm-2"></div>
</div>
</div>
</div>

      
    </div>
  )
}

export default Join