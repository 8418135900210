import React, { useRef, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import './Review.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import "swiper/css";
import "swiper/css/navigation";
import {Autoplay, Navigation } from "swiper";




const Review = () => {
  return (




    <section id="Review-containt">
          <div className="container-fluid">
       <div className="row reviewbtn">
         <div className="col">
        <div className="container">
       <div className="row">
        <div className="col-lg-5 col-md-12 col-12 ">
           <div className="slide">
            <p>what people say</p>
        <span className="robot">about us!</span>
                  <h4 className='letter'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h4>
                 </div>
        </div>
       <div className="col-lg-7 col-md-12 col-12 review-slide">

       <Swiper
        slidesPerView={1}
        spaceBetween={10}
        autoplay={{
            delay:2500,
            disableOnInteraction: false,
        }}
        navigation={true}

        modules={[ Autoplay,Navigation]}
        className="mySwiper"
      >





       <SwiperSlide>
        <div className="swiper-client-msg">
            <p>
            I have just received my beautiful delicate Christmas tree charm and it made me smile! I am so happy I chose your jewellery in the end and paid more for such an exquisite piece. I looked at many websites, but none came close.
            </p>
            </div> 
            <div className="swiper-client-data ">
                <figure>
                <img className='review-image' src="images/Ellipse 3.png" alt="clients review"/>

                </figure>
                <div className="client-data-details">
                  <p>Anushka Sharma</p>
                  <p>commodo consequat.</p>
                </div>

            </div> 
         </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-client-msg">
            <p>
            I have just received my beautiful delicate Christmas tree charm and it made me smile! I am so happy I chose your jewellery in the end and paid more for such an exquisite piece. I looked at many websites, but none came close.
            </p>
            </div> 
            <div className="swiper-client-data ">
                <figure>
                <img className='review-image' src="images/Ellipse 3.png" alt="clients review"/>

                </figure>
                <div className="client-data-details">
                  <p>Anushka Sharma</p>
                  <p>commodo consequat.</p>
                </div>

            </div> 
        </SwiperSlide>
        <SwiperSlide>
        <div className="swiper-client-msg">
            <p>
            I have just received my beautiful delicate Christmas tree charm and it made me smile! I am so happy I chose your jewellery in the end and paid more for such an exquisite piece. I looked at many websites, but none came close.
            </p>
            </div> 
            <div className="swiper-client-data ">
                <figure>
                <img className='review-image' src="images/Ellipse 3.png" alt="clients review"/>

                </figure>
                <div className="client-data-details">
                  <p>Anushka Sharma</p>
                  <p>commodo consequat.</p>
                </div>

            </div> 
        </SwiperSlide>
        </Swiper>


     



        </div> 
        </div>
        </div>
        </div>
        </div>
        </div>
       

    
  </section>

    )
}

export default Review




// import React from 'react'
// import './Review.css'

// const Review = () => {
//   return (
//     <div>
//         <section id="Review-containt">
//         <div class="container-fluid">
//   <div class="row">
//     <div class="col">
//     <div className="container">
//   <div className="row">
//     <div className="col-lg-6 col-md-6 col-12 ">
//       <div className="slide">
//     <i className="crousel" >See Us <span className="robot">Catagories</span></i>
//              <h4 className='letter'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</h4>
//              </div>
//     </div>
//     <div className="col-lg-6 col-md-6 col-12">
   
//     <div class="col">
    



 
//     </div>
//     </div>
//   </div>
//   </div>
//     </div>
//     </div>
//     </div>
//     </section>



//     <div class="container">
//         <div class="row">
//             <div class="col-md-12">
//                 <div class="carousel slide" data-ride="carousel" id="quote-carousel">
//                     {/* <!-- Carousel Slides/Quotes --> */}
//                     <div class="carousel-inner text-center">
//                         {/* <!-- Quote 1 --> */}
//                         <div class="item active">
//                             <blockquote>
//                                 <div class="row">
//                                     <div class="col-sm-8 col-sm-offset-2">
//                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. !</p>
//                                         <small>Someone famous</small>
//                                     </div>
//                                 </div>
//                             </blockquote>
//                         </div>
//                         {/* <!-- Quote 2 --> */}
//                         <div class="item">
//                             <blockquote>
//                                 <div class="row">
//                                     <div class="col-sm-8 col-sm-offset-2">
//                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
//                                         <small>Someone famous</small>
//                                     </div>
//                                 </div>
//                             </blockquote>
//                         </div>
//                         {/* <!-- Quote 3 --> */}
//                         <div class="item">
//                             <blockquote>
//                                 <div class="row">
//                                     <div class="col-sm-8 col-sm-offset-2">
//                                         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. .</p>
//                                         <small>Someone famous</small>
//                                     </div>
//                                 </div>
//                             </blockquote>
//                         </div>
//                     </div>
//                     {/* <!-- Bottom Carousel Indicators --> */}
//                     <ol class="carousel-indicators">
//                         <li data-target="#quote-carousel" data-slide-to="0" class="active"><img class="img-responsive " src="https://randomuser.me/api/portraits/men/46.jpg" alt=""/>
//                         </li>
//                         <li data-target="#quote-carousel" data-slide-to="1"><img class="img-responsive" src="https://uifaces.co/our-content/donated/1H_7AxP0.jpg" alt=""/>
//                         </li>
//                         <li data-target="#quote-carousel" data-slide-to="2"><img class="img-responsive" src="https://randomuser.me/api/portraits/men/36.jpg" alt=""/>
//                         </li>
//                     </ol>

//                     {/* <!-- Carousel Buttons Next/Prev --> */}
//                     <a data-slide="prev" href="#quote-carousel" class="left carousel-control"><i class="fa fa-chevron-left"></i></a>
//                     <a data-slide="next" href="#quote-carousel" class="right carousel-control"><i class="fa fa-chevron-right"></i></a>
//                 </div>
//             </div>
//         </div>
    
//     </div>
//     </div>
//   )
// }

//  export default Review