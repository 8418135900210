import React from 'react'
import './SellingProduct.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faShoppingCart,faSearch,faHeart,faUser, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';


import {
    FavoriteBorderOutlined,
    ShoppingCartOutlined
   
  } from "@material-ui/icons";






const SellingProduct = () => {
  return (
    <div>
        <section className='container-fluid top'>


        <div className="row">
            <div className='col-6 product'>
         <span className='card-top-contain'>Top</span> <i className="card-top-normal" >Selling Product</i>
           </div>
            <div className='col-6 view-all'>
            <i className='hidden-view'>View all</i>
             </div>
        <div className="col-lg-3 col-md-6">
<div className="card rem lines">
 <div className='image-top'>
  <img src="./images/image.png" className="card-img-top img-ring" alt="" />
    <div className='heart-icon'>
     <FavoriteBorderOutlined  />
    </div>
</div>
  <div className="card-body d-flex start">
<div>
  <p className="text">Name of the Jewellery</p>
    <p className="card-text ">₹4000.00</p>
</div>
    <div className='bag'>
        <div className='circle'>
    <FontAwesomeIcon className='Bag-icon'  icon={faShoppingBag}   />
    </div>
 </div>
      </div>
     </div>
    </div>

    
    
    <div className="col-lg-3 col-md-6">
<div className="card rem lines">
 <div className='image-top'>
  <img src="./images/image.png" className="card-img-top img-ring" alt="" />
    <div className='heart-icon'>
     <FavoriteBorderOutlined  />
    </div>
</div>
  <div className="card-body d-flex start">
<div>
  <p className="text">Name of the Jewellery</p>
    <p className="card-text ">₹4000.00</p>
</div>
    <div className='bag'>
        <div className='circle'>
    <FontAwesomeIcon className='Bag-icon'  icon={faShoppingBag}   />
    </div>
 </div>
      </div>
     </div>
    </div>
    
    

    <div className="col-lg-3 col-md-6">
<div className="card rem lines">
 <div className='image-top'>
  <img src="./images/image.png" className="card-img-top img-ring" alt="" />
    <div className='heart-icon'>
     <FavoriteBorderOutlined  />
    </div>
</div>
  <div className="card-body d-flex start">
<div>
  <p className="text">Name of the Jewellery</p>
    <p className="card-text ">₹4000.00</p>
</div>
    <div className='bag'>
        <div className='circle'>
    <FontAwesomeIcon className='Bag-icon'  icon={faShoppingBag}   />
    </div>
 </div>
      </div>
     </div>
    </div>

    
    
    
    <div className="col-lg-3 col-md-6">
<div className="card rem lines">
 <div className='image-top'>
  <img src="./images/image.png" className="card-img-top img-ring" alt="" />
    <div className='heart-icon'>
     <FavoriteBorderOutlined  />
    </div>
</div>
  <div className="card-body d-flex start">
<div>
  <p className="text">Name of the Jewellery</p>
    <p className="card-text ">₹4000.00</p>
</div>
    <div className='bag'>
        <div className='circle'>
    <FontAwesomeIcon className='Bag-icon'  icon={faShoppingBag}   />
    </div>
 </div>
      </div>
     </div>
    </div>

    
    
    

    <div className="d-grid gap-2 col-3 mx-auto space">
    <button type="button" className="btn btn-outline-dark lightbtn">Load More</button>
</div>
  </div>
    
        
        
        </section>


     

    </div>
  )
}

export default SellingProduct