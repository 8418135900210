import React from 'react'
import './Newproduct.css'
import background from '../img/newarrival.png'

const Newproduct = () => {
  return (
    <div>
    
         <section className='new-arrival'  style={{ backgroundImage: `url(${background})`}}>
        <div className="container" >
          <div className='row'>
            <div className='col-5 between'>
             <span className="new-contain-center">New Arrivals</span>
             <p className='new-contain-small'>All new range of diyora diamonds to light up your every occasion.</p>
             <button type="button" className="btn btn-outline-light btn-lg button newproduct-btn " >Learn More</button>
            </div>
            </div>
        </div>
        </section>

    </div>
  )
}

export default Newproduct