import React from 'react'
import './Frame.css'

const Frame = () => {
  return (
    <div>
        <div className="container-fluid ">
  <div className="row p-0">
    <div className="col-lg-3 column-1">
  <img src="./images/Rectangle 17.png" className="card-img-top img-responsive img-height" alt="" />
    {/* <img src={} class="img-thumbnail" alt="..."> */}
    </div>
    <div className="col-lg-3 column-1"> 
     <img src="./images/Rectangle 17 (1).png" className="card-img-top img-responsive img-height" alt="" />
</div>
    <div className="col-lg-3 column-1">
     <img src="./images/Rectangle 17 (2).png" className="card-img-top img-responsive img-height" alt="" />
        
    </div>
    <div className="col-lg-3 column-1">
    <img src="./images/Rectangle 17 (3).png" className="card-img-top img-responsive img-height" alt="" />
    
    </div>
  </div>
  </div>




  {/* <h1 className='text-center'>silvarooooooo</h1> */}

    </div>
  )
}

export default Frame