import React from 'react'
import './Orderandshipping.css'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
const OrderAndshipping = () => {
  return (
    <div>
        <br/>
        <div class="container">
     <div class="row">   
     <div class="col-sm-12"><h1 class="term"><span style={{color: "#00b7eb"}}>Order an</span><span style={{color: "#FFD700"}}>d Shipping </span></h1><br/>
     <p style={{color:"black",textAlign:"center",fontSize:"18px",fontWeight:"bold"}}>SILVAROO jewelry brand only needs the customer's 
        satisfaction for this purpose SILVAROO introduces the Free Shipping offer when your order is above Rs.100.</p>
     </div>
     </div><hr/>
     <br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Order from SILVAROO</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p style={{color:"black",fontSize:"16px"}}>For placing an order at SILVAROO, you need to follow the following steps:</p>
        <ul >
            <li class="order-li">1.	Firstly you need to find out which product you want to buy.</li>
            <li class="order-li">2.	After finding Add your product into your cart by clicking on the option Add to bag/cart.</li>
            <li class="order-li">3.	After adding, now confirm the order by providing your accurate personal information.</li>
            <li class="order-li">4.	After placing your order, you’ll get the confirmation message from our official email address or contact number.</li>
            <li class="order-li">5.	You can also keep in contact with our team on WhatsApp and email.</li>
        </ul>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Updation of Order</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <ul>
            <li class="order-li">➔	You will get a notification via SMS or E-MAIL when your order consigns from our warehouse.</li>
            <li class="order-li">➔	Our Order chiefly delivered in 3-4 working days. </li>
            <li class="order-li">➔	When your order is ready to dispatch, we send you a Shipment tracking number that helps you to track your order.</li>
            <li class="order-li">➔	We try our best to deliver your order product as soon as possible</li>
            <li class="order-li">➔	Unfortunately, sometimes due to an accidental situation we may face some problem in the delivery of products, in that case,
                 we contact our customer immediately to update them from the present condition</li>
        
        </ul>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Payment Method</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <p style={{color:"black",fontSize:"16px"}}>SILVAROO provide their customer with every type of comfort regarding Payment method.
Here we have three methods for the payment of an order. Let’s have a look at payment methods.
</p>
<ul>
    <li class="order-li">❖	Payment via Credit/Debit card before receiving your Order.</li>
    <li class="order-li">❖	Cash Payment to the courier man.</li>
    <li class="order-li">❖	Payment via Credit/Debit card after receiving your order.</li>
    
</ul>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Payment Via Credit card before receiving your order</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <ul>
            <li>❖ You can directly pay for your ordered product using your VISA or MASTERCARD credit/debit.</li>
        </ul><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Cash payment to the courier man</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <ul>
            <li class="order-li">❖ We provide our valued customer with the facility to pay cash to the courier man while receiving your order.
                 It is beneficial for those people who don’t have a bank account.</li>
        </ul><br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order"> Payment Via Credit card after receiving your order</p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
        <ul>
            <li class="order-li"> ❖ Sometimes, we have no money change at that time SILVAROO provide their customer to pay for 
                their order online in front of the courier man.
                 You can request our courier man to receive the payment via UPL.</li>
           
        </ul>
        <br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Important Point That you should keep in the notice </p></div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12">
       <ul>
        <li class="order-li">●	Please do not receive your order if the seal is breaking. (because sometimes the courier company does not handle it carefully).</li>
        <li class="order-li">●	If you open your order and wish that you don't want this product, the courier man will not bring back your opened order.</li>
        <li class="order-li">●	If you can’t receive your order and do not pay for it, Then your order considers canceled.</li>
        <li class="order-li">●	If your product is not the same as your order then we feel happy to introduce our 7-day, no-questions-asked return policy.</li>
        <li class="order-li">●	We are trying our best to upload the product’s real picture in which all its colors are clearly visible but in case of any misguidance, You also have an opportunity to exchange your ordered
             product as we want to enhance the happiness of our beloved customers.</li>
       </ul>
       <p style={{marginLeft:"30px"}}>We hope that you will corporate with us. </p>
       <br/>
    </div>
     </div><br/>

     <div class="row">   
     <div class="col-sm-12"></div>
     </div>
     
    </div>
    </div>
  )
}

export default OrderAndshipping