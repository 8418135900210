import React,{useState,useEffect} from 'react'
import background from "../img1/banner1.png"
import "./Shopnow.css"
import MultiRangeSlider from '../../Component/multiRangeSlider/MultiRangeSlider'



const Shopnow = () => {
  const [value,onChange]=useState(1);
  useEffect(()=>{
    const ele = document.querySelector('.buble');
  if (ele) {
    ele.style.left = `${Number(value / 4)}px`;
  }
})
  return (
    <div>
      <div className='container-fluid '>
        <section id="shopnow" style={{ backgroundImage: `url(${background})` }}>
          {/* <div className="container" >
          <div className='row '>
            <div className='col-lg-6 col-md-12  mt-5 '>
            <i className="italic" >Gifts for <span className="ctrl">Silvaroo</span></i>
            <hr className='horizontal'/>
             <p className='bond'>As special as your bond</p>
             <button type="button" className="btn btn-outline-light btn-lg button " >SHOP NOW</button>
            </div>
            </div>
        </div> */}
        </section>
      </div>

      <div class="container" style={{ padding: "10px 0px" }} >
        
        <ul class="nav  ">
          <li class="nav-item dropdown ">
            <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
             product Type
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><a class="dropdown-item" href="#"><div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
   Rings
  </label>
</div></a></li>
              <li>
        
                </li>
              <li><hr class="dropdown-divider" /></li>
    
            </ul>
          </li>
          <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Price
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style={{padding:"60px 6px"}}>
            <li>
<li > <MultiRangeSlider
      min={0}
      max={1000}
      onChange={({ min, max }) => console.log(`min = ${min}, max = ${max}`)}
      
    /></li>

            </li>
            
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-dark " href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
           Stone
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">
     
</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Color
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Style
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        </ul>
        <ul class="nav justify-content-end ">
        <li class="nav-item dropdown selling ">
          <a class="nav-link dropdown-toggle text-dark" style={{border:"1px solid grey"}} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          SOER BY:BEST SELLING          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">
            <div class="form-check">
  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label class="form-check-label" for="flexCheckDefault">
   Rings
  </label>
</div></a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider"/></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        </ul>
        <br/>
        <hr/>
        
      </div>

     
            
        

      

      <div class="container  py-4 ">
        <div class="row">
          <div class="col">
            <div class="card card1">
              <img src="images/Rectangle 17 (1).png" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="images/Rectangle 17 (2).png" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="images/Rectangle 17 (3).png" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card">
              <img src="images/Rectangle 17.png" class="card-img-top" alt="..." />
              <div class="card-body">
                <h5 class="card-title">Card title</h5>
                <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <form>
        
	</form>



    </div>
  )
}

export default Shopnow