import React from 'react'
import './Productcare.css'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Productcare = () => {
  return (
    <div>
        <div class="container">
    <div class="row">   
    <div class="col-sm-12"><h1 class="term"><span style={{color: "#00b7eb"}}>Produc</span><span style={{color: "#FFD700"}}>t Care </span></h1><br/>
    {/* <p style={{color:"black",textAlign:"center",fontSize:"18px",fontWeight:"bold"}}>SILVAROO jewelry brand only needs the customer's 
       satisfaction for this purpose SILVAROO introduces the Free Shipping offer when your order is above Rs.100.</p> */}
    </div>
    </div><hr/>
    <br/>

    <div class="row">   
    <div class="col-sm-12"><p class="heading-order">Product Care</p></div>
    </div><br/>

    <div class="row">   
    <div class="col-sm-12">
       <p style={{color:"black",fontSize:"16px"}}>
       SILVAROO always tries to convey high-quality jewelry to you. While Delivering, we handle our jewelry with love and care just for our valuable customers. 
       It is necessary to take care of your jewelry products. Otherwise, it will lose its shine as well as its color and seems as if you are wearing your old jewelry. SILVAROO deals with the best quality. That’s why Silvaroo’s jewelry products have an additional coating of  “Rhodium”.
       Rodium is a valuable metal that protects the color of the jewelry and made it long-lasting for use.
        Rhodium also adds extra shine and prevents our jewelry from corrosion and scratches. 
        If your jewelry looks good then it also adds more charm to your personality. 
        Sulphur present in the air reduces the strength of Rhodium and causes serious damage to the jewelry.
        Continuous  Direct contact of SILVAROO jewelry with sulphur can lead your jewelry towards damaging.
       </p><br/>
       <p>
       Here are some important tips that help you to protect your SILVAROO jewelry.
       </p>

       <ul>
           <li class="order-li">➢	Place your Silvaroo jewelry in an air-tight bag so that it can not make contact with Sulphur gas.</li>
           <li class="order-li">➢	Do not wear Silvaroo jewelry while washing clothes, dishes, or bathing.</li>
           <li class="order-li">➢	Avoid using fragrances or makeup liquids on Silvaroo jewelry because they leave a serious impact on your Silvaroo jewelry.</li>
           <li class="order-li">➢	Prevent your Silvaroo jewelry from collision, or smash as it causes damage to your jewelry. </li>
        
       </ul>
       <p style={{color:"black",fontSize:"16px"}}>SILVAROO  jewelry is made up of 9.25% pure silver that does not get corrosion easily. 
        We can easily clean all the dirt present on the Silvaroo jewelry and it is the top best quality of our product. 
        We desire that your jewelry always remain the same.
        In case of trauma, when your jewelry turns black and you need to repolish it,
        you just need to take it to a nearby jewelry shop that will repolish your jewelry and make it new.</p>
   </div>
    </div><br/>
    <div class="row">   
    <div class="col-sm-12"></div>
    </div>
    
   </div></div>
  )
}

export default Productcare