import React from 'react'
import './Returnandrefund.css'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

const Returnandrefund = () => {
  return (
    <div><div class="container">
    <div class="row">   
    <div class="col-sm-12"><h1 class="term"><span style={{color: "#00b7eb"}}>Refund an</span><span style={{color: "#FFD700"}}>d Return </span></h1><br/>
    {/* <p style={{color:"black",textAlign:"center",fontSize:"18px",fontWeight:"bold"}}>SILVAROO jewelry brand only needs the customer's 
       satisfaction for this purpose SILVAROO introduces the Free Shipping offer when your order is above Rs.100.</p> */}
    </div>
    </div><hr/>
    <br/>

    {/* <div class="row">   
    <div class="col-sm-12"><p class="heading-order">Product Care</p></div>
    </div><br/> */}

    <div class="row">   
    <div class="col-sm-12">
       <p style={{color:"black",fontSize:"16px"}}>
       SILVAROO knows quite well that shopping online is not easy at all.
        That’s the reason we offer a very lenient 15-day return policy.
         This return policy will be applicable to all the unworn and unused items and with no question being asked. 
         There’s just one thing you need to take care of: the jewelry should not have been used and must be in its original packaging.
          We check the returned items and then we process your refund. 
          But if you request a refund after 15 days then we’ll be sorry for not returning your product. 
       </p>
       
   </div>
    </div><br/>
    <div class="row">   
    <div class="col-sm-12"><p class="heading-order">Our Exchange Policy</p></div>
    </div><br/>
    <div class="row">   
    <div class="col-sm-12">
       <p style={{color:"black",fontSize:"16px"}}>
       Got an incorrect or damaged piece of jewelry? Or simply just don’t like the design of the jewelry after
        seeing it physically? No worries!! SILVAROO got you…  
       </p><br/>
       <p style={{color:"black",fontSize:"16px"}}>We offer a 15 days exchange policy in which you can replace your item with a similar item or with a different item. 
        The CHOICE is YOURS! In case of an exchange of different items, the price difference will be refunded /collected to you according to the situation.
         All the exchanged orders will take 48-72 hours to shipment.
         If you want to exchange any item you can call us at <strong>+91-9987886160</strong> or simply just email us at<a href='customercare@silvaroo.in'> customercare@silvaroo.in</a>
         </p>
       </div></div><br/>


  <div class="row">   
    <div class="col-sm-12"><p class="heading-order">Our Refund Process</p></div>
    </div><br/> 

<div class="row">   
    <div class="col-sm-12">
       <p style={{color:"black",fontSize:"16px"}}>
       Refund is generated through payout links for Cash On delivery shipment which makes refund super convenient and fast.
        We do not collect bank details to avoid waste of time and hassle. 
        Our team will issue you a payout link after picking up the item. 
        By payout link you can easily get your refund in the following way: 
       </p>
       

       <ul>
           <li class="order-li">➢	By SMS or email, the payout link will be sent to you.</li>
           <li class="order-li">➢	Confirm your phone number through OTP after clicking the link.</li>
           <li class="order-li">➢	Bank account or UPI; you can easily choose where you want the refunded money.</li>
           <li class="order-li">➢	Ta-Da! Payout is in your account now. </li>
        
       </ul>
       <p style={{color:"black",fontSize:"16px"}}>
        Despite following the above process, if you are unable to get the refund
        please contact us at <strong>+91-9987886160</strong> or <a href="customercare@silvaroo.in">customercare@silvaroo.in</a> It will be our pleasure to help you. 
      </p> 
   </div>
    </div><br/>

    <div class="row">   
    <div class="col-sm-12"><p class="heading-order">Refund Policy</p></div>
    </div><br/> 

<div class="row">   
    <div class="col-sm-12">
       <p style={{color:"black",fontSize:"16px"}}>
       From the day of booking the return, our pickup partners will reverse pickup in 3 working days after the generation of 
       your return request which you generated via mail or on the web. We initiate the return within 48 hours after getting 
       notified about a successful return pickup. Without a return become, cannot initiate the refund. 
       </p>
       

       <ul>
           <li class="order-li">➢	Returns are initiated directly to the original payment source for prepaid orders.</li>
           <li class="order-li">➢	The COD customers who are opted to return get a Razorpay payout link to initiate returns.
                                    Please choose the appropriate mode of accepting payment and enter the correct details in the link.</li>
           <li class="order-li">➢	For Wallet orders refunded amount will go back into the wallet. In line with RBI guidelines,
                                        it should be strictly noted that wallet refunds cannot be done to any other payment source.</li>
           <li class="order-li">➢	All refunds will be given in the exact proportion in which the payments were received including partial refunds
                                    and this is applicable for any combination of all the above delivery methods. </li>
        
       </ul>
       
   </div>
    </div>
    
   </div></div>
  )
}

export default Returnandrefund