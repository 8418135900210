import React from 'react'
import './Collections.css'
import background from '../img/unsplash.png'
import unsplash from '../img/unsplash1.png'
import unsplash1 from '../img/unsplash2.png'
import unsplash2 from '../img/unsplash3.png'




const Collections = () => {
  return (
    <div>
        <section id='collection'>
        <div className='container'>
         <p className='collections-top-contain'>Collections</p> 
         <p className='collections-para col-lg-10'>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
         
     
       


         <div class="row gap-top">
        <div class="col-lg-7 collection-img" >
          <section id="main-img-card" style={{ backgroundImage: `url(${background})`}}>
        <div className="container" >
          <div className='row '>
            <div className='col-10'>
                <i className='card-small-contain'>Womens wear are selling on</i>
            <i className="card-off" >37% Off </i>
             <p className='lorem'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
             <button type="button" className="btn btn-outline-light btn-lg button-shop-now " >SHOP NOW</button>
            </div>
            </div>
        </div>
        </section>
  </div>
       <div class="col-lg-5">
       <section id="main-img-card" style={{ backgroundImage: `url(${unsplash})`}}>
        <div className="container" >
          <div className='row'>
         <div className='col-12 '>
    <div class="col-lg-9 offset-lg-4">
            <i className='card-small-contain'>Womens wear are selling on</i>
            <i className="card-off" >17% Off </i>
             <p className='lorem-17'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
             <button type="button" className="btn btn-outline-light btn-lg button-shop-now " >SHOP NOW</button>
            </div>
            </div>
            </div>
        </div>
        </section>
       </div>

       <div class="col-lg-6">
       <section id="neck-img" style={{ backgroundImage: `url(${unsplash1})`}}>
        <div className="container" >
          <div className='row'>
         <div className='col-12 '>
    <div class="col-lg-7 offset-lg-5">
            <i className='card-small-contain'>Womens wear are selling on</i>
            <i className="card-off" >27% Off </i>
             <p className='lorem-17'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
             <button type="button" className="btn btn-outline-light btn-lg button-shop-now " >SHOP NOW</button>
            </div>
            </div>
            </div>
        </div>
        </section>
       </div>

       <div class="col-lg-6 collection-img text-dark" >
          <section id="ring-img" style={{ backgroundImage: `url(${unsplash2})`}}>
        <div className="container" >
            <div className='row '>
            <div className='col-10'>
                <i className='card-small-contain-09'>Womens wear are selling on</i>
            <i className="card-off-09" >0.9% Off </i>
             <p className='lorem-09'>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
             <button type="button" className="btn btn-outline-dark btn-lg button-shop-now " >SHOP NOW</button>
            </div>
            </div>
        </div>
        </section>
  </div>
       </div>
       </div>

        
        </section>
        </div>
  )
}

export default Collections