import React from 'react'
import './Womens.css'

const Womens = () => {
  return (
    <div>
        <div class="navbar  navbar-light bg-light ">
  
          
  <div class="container-fluid">
    <a class="navbar-brand womens-brand" href="/">Women's</a>
    
 
  <div class="dropdown">
  <button class="btn btn-secondary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
    sort product
  </button>
  <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
    <li><a class="dropdown-item " href="#">Newest</a></li>
    <li><a class="dropdown-item" href="#">price (asc)</a></li>
    <li><a class="dropdown-item" href="#">price (des) </a></li>
   
  </ul>
</div>
</div>
  

</div>


<div class="container  py-4 ">
  <div class="row">
    <div class="col">
    <div class="card card1">
  <img src="images/Rectangle 17 (1).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (2).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (3).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17.png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
  </div>
    </div>  


    <div class="container text-center py-4">
  <div class="row">
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (1).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (2).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17 (3).png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
    <div class="col">
    <div class="card">
  <img src="images/Rectangle 17.png" class="card-img-top" alt="..."/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text cardretext">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-outline-dark cardbtn">Go somewhere</a>
  </div>
</div>
    </div>
  </div>
    </div> 
    <div class="d-grid gap-2 col-4 mx-auto">
    <button type="button" class="btn btn-outline-secondary loadbtn" >Load More</button>
</div>  
    </div>
  )
}

export default Womens