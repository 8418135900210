import React from 'react'
import './About.css'

const About = () => {
  return (
    <div><div class="about-section">
    <h1>About Us</h1>
    {/* <p>Some text about who we are and what we do.</p>
    <p>Resize the browser window to see that this page is responsive by the way.</p> */}
  </div><br/><br/>
  
  <div class="container">
  <div class="row">   
  <div class="col-sm-12"><h1 class="term"><span style={{color: "#00b7eb"}}>Why </span><span style={{color: "#FFD700"}}>Silvaroo..?</span></h1><br/>
     <p style={{color:"black",textAlign:"center",fontSize:"18px",fontWeight:"bold"}}>
     Silvaroo is a jewelry brand that started in 2021. It deals with highest -quality 925 Sterling Silver. Silvaroo manufactures jewelry that is easily affordable. All the jewelry has very good quality as they are made up of 92.5% pure silver.  All the designs are fashionable as well as contemporary. 
     Silvaroo works on the satisfaction of their beloved customers.
        </p><hr/>
     </div>
  </div><br/><br/><br/>

  {/* <div class="row">   
     <div class="col-sm-4"><p class="heading-order">Mission</p></div>
     </div> */}

     <div class="row">   
     <div class="col-sm-4 " style={{border:"0px solid grey"}}>
      
        <h2 class="heading-about" >Mission</h2>
        <p  style={{color:"black",fontSize:"16px", wordWrap: "break-word"}}>
        Our Mission is to enhance your beauty and your happiness. We did this job perfectly with our most beautiful SILVER jewelry that is designed just for you. Our jewelry is Graceful as well as fashionable and grooms your personality. 
        We deal with the smile of our beautiful customers.</p>
      
        
    </div>
     {/* </div><br/> */}

     {/* <div class="row">    */}
     {/* <div class="col-sm-12"><p class="heading-order">Vision</p></div> */}
     {/* </div> */}

     {/* <div class="row">    */}
     <div class="col-sm-4"style={{border:"0px solid grey"}}>
    
        <h2 class="heading-about" >Vision</h2>
        <p style={{color:"black",fontSize:"16px",wordWrap: "break-word"}}>
        Our Great Vision is to make every day special for everyone and make you feel superior in front of people. 
        We want to provide you with the confidence to live and enjoy your life fully.</p>
      
    </div>
     {/* </div><br/> */}

     {/* <div class="row">   
     <div class="col-sm-12"><p class="heading-order">Our Promise</p></div>
     </div> */}

     {/* <div class="row">    */}
     <div class="col-sm-4"style={{border:"0px solid grey"}}>
  
        <h2 class="heading-about" >Our Promise</h2>
        <p style={{color:"black",fontSize:"16px",wordWrap: "break-word"}}>
        We promise to become your most trusted jewelry brand, for this,
         we guarantee the exceptional craftsmanship and quality of all material used 
        and our commitment towards quality and purity
.</p>


        
    </div>
     </div><br/>
  </div>
    
    
    
   
 
  </div>
  )
}

export default About